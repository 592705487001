import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Theme, useTheme} from '@mui/material';

/**
 * 引数
 */
interface Props {
  source: {total_count: string; ng_count: string};
  height?: string;
}

const COLOR_OK = '#4CAF50';
const COLOR_NG = '#F44336';

const getRich = (theme: Theme) => {
  return {
    center_title: {
      fontSize: '0.875rem',
      color: theme.palette.text.primary,
      padding: [0, 0, 5, 0]
    },
    center_hr: {
      borderColor: theme.palette.divider,
      width: '100%',
      align: 'left',
      borderWidth: 1,
      height: 0
    },
    center_val: {
      fontSize: '2rem',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      padding: [7, 0, 0, 0]
    },
    title_ok: {
      fontSize: '0.875rem',
      color: theme.palette.background.default,
      textBorderWidth: 0,
      backgroundColor: theme.palette.text.secondary,
      padding: [3, 5],
      align: 'center'
    },
    title_ng: {
      fontSize: '0.875rem',
      color: theme.palette.background.default,
      textBorderWidth: 0,
      backgroundColor: theme.palette.text.secondary,
      padding: [3, 5],
      align: 'center'
    },
    val: {
      fontSize: '1.75rem',
      color: theme.palette.text.primary,
      // fontWeight: 'bold',
      verticalAlign: 'bottom',
      padding: [2, 0, 2, 0],
      align: 'center'
    },
    val_sub: {
      fontSize: '0.875rem',
      color: theme.palette.background.default,
      textBorderWidth: 0,
      backgroundColor: theme.palette.text.secondary,
      padding: [0, 5],
      align: 'center'
    }
  };
};

/**
 * LFCOkNgPie2
 * @param props
 * @returns
 */
const LFCOkNgPie2 = React.memo((props: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [dsPop, setDsPop] = useState([{}]);
  const [dsCenter, setDsCenter] = useState([{}]);

  useEffect(() => {
    setDsPop([
      {name: 'OK', value: Number(props.source.total_count) - Number(props.source.ng_count)},
      {name: 'NG', value: Number(props.source.ng_count)}
    ]);
    setDsCenter([{name: 'total', value: props.source.total_count}]);
  }, [props.source]);

  return (
    <LFCBaseCharts
      option={{
        legend: {show: false},
        toolbox: {show: false},
        tooltip: {show: false},
        series: [
          {
            name: 'pop',
            type: 'pie',
            radius: ['60%', '82%'],
            data: dsPop,
            itemStyle: {
              color: (data: any) => {
                return data['name'] === 'OK' ? COLOR_OK : COLOR_NG;
              }
            },
            labelLayout: {
              draggable: true
            },
            label: {
              show: true,
              position: 'inside',
              borderWidth: 2,
              borderColor: theme.palette.divider,
              padding: [4, 0],
              rich: getRich(theme),
              formatter: (data: any) => {
                const styleTitle = data['name'] === 'OK' ? 'title_ok' : 'title_ng';
                const strP = {
                  title: data['name'],
                  val: data['value'].toLocaleString(),
                  val_unit: 'sec',
                  val_sub: data['percent'] ?? 0 + '%'
                };
                return `{${styleTitle}|${strP.title}}\n {val|${strP.val}} \n {val_sub|${strP.val_sub}} `;
              }
            }
          },
          {
            z: 10,
            name: 'total',
            type: 'pie',
            radius: 0,
            data: dsCenter,
            itemStyle: {
              color: 'transparent'
            },
            label: {
              show: true,
              position: 'center',
              rich: getRich(theme),
              formatter: (data: any) => {
                const strP = {
                  title: t('総数'),
                  val: data['value'].toLocaleString()
                };
                return `{center_title|${strP.title}}\n{center_hr|}\n {center_val|${strP.val}} `;
              }
            }
          }
        ]
      }}
      height={props.height}
    />
  );
});
export default LFCOkNgPie2;
