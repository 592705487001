import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  name: string;
  data: any;
  x: {
    type: 'time' | 'value' | 'category';
    name?: string;
    dsColumn: string;
    data?: any;
  };
  y: {
    type: 'time' | 'value' | 'category';
    name?: string;
    dsColumn: string;
  };
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  height?: string;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
}

/**
 * LFCBarChart
 * @param props
 * @returns
 */
const LFCBarChart = React.memo((props: Props) => {
  const {t} = useTranslation();

  type DsRow = {source?: []; transform?: any};
  const [dataset, setDataset] = useState<DsRow[]>([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    // データソースクリア
    if (props.data.length === 0) {
      setSeries([]);
      setDataset([]);
      return;
    }

    // データソース生成
    const l_ds: DsRow[] = [];
    const l_series: any = [];
    l_ds.push({source: props.data});

    if (props.series) {
      // シリーズ指定あり

      // データ配列からシリーズ列の値を重複を除去して抽出
      const strSeries: string = props.series;
      const grpNames = Array.from(new Set(props.data.map((obj: any) => obj[strSeries])));

      // シリーズ設定
      grpNames.forEach((grp, i) => {
        l_ds.push({
          transform: {
            type: 'filter',
            config: {dimension: strSeries, value: grp}
          }
        });

        l_series.push({
          type: 'bar',
          name: grp,
          stack: props.stacked ? 'total' : null,
          dimensions: [props.y.dsColumn, props.x.dsColumn],
          encode: {y: props.y.dsColumn, x: props.x.dsColumn},
          datasetIndex: i + 1
        });
      });
    } else {
      // シリーズ指定なし
      l_series.push({
        type: 'bar',
        dimensions: [props.y.dsColumn, props.x.dsColumn],
        encode: {y: props.y.dsColumn, x: props.x.dsColumn}
      });
    }
    setSeries(l_series);
    setDataset(l_ds);
  }, [props.data]);

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.name
        },
        grid: props.grid || {
          top: 60,
          left: 60,
          right: 10
        },
        ...(props.series
          ? {
              legend: {type: 'scroll', bottom: 0, itemWidth: 10}
            }
          : {
              legend: {
                show: false
              }
            }),
        tooltip: {
          trigger: 'item'
          // formatter: (params: any) => {
          //   let tmp: string = '';
          //   if (params.data.map !== undefined) {
          //     tmp =
          //       params.data.lq_time +
          //       '<br/>' +
          //       t('部位') +
          //       '： ' +
          //       params.data.map +
          //       '<br/>' +
          //       t('不良数') +
          //       '： ' +
          //       params.data.ng +
          //       '<br/>';
          //   } else if (params.data.work !== undefined) {
          //     tmp =
          //       params.data.lq_time +
          //       '<br/>' +
          //       t('機種') +
          //       '： ' +
          //       params.data.work +
          //       '<br/>' +
          //       t('不良数') +
          //       '： ' +
          //       params.data.ng +
          //       '<br/>';
          //   } else if (params.data.job_type_name !== undefined) {
          //     tmp =
          //       params.data.lq_time +
          //       '<br/>' +
          //       t('検査手法') +
          //       '： ' +
          //       params.data.job_type_name +
          //       '<br/>' +
          //       t('不良数') +
          //       '： ' +
          //       params.data.ng +
          //       '<br/>';
          //   }
          //   return tmp;
          // }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        color: props.color || null,
        dataset: dataset,
        xAxis: [
          {
            type: props.x.type,
            name: props.x.name || null,
            axisLabel: {
              rotate: '45',
              fontSize: 10
            },
            data: props.x.data || null
          }
        ],
        yAxis: [
          {
            type: props.y.type,
            name: props.y.name || null
          }
        ],
        series: series
      }}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={props.height}
    />
  );
});
export default LFCBarChart;
