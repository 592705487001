import {Box, Card, CardContent, Tooltip, Typography} from '@mui/material';
import {Variant} from '@mui/material/styles/createTypography';
import {makeStyles} from '@mui/styles';

/**
 * Style
 */
const useStyles = makeStyles({
  root: {
    '& div': {
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  }
});

/**
 * パラメータ
 */
interface Props {
  title: string;
  val: any;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  fontColor?: string;
  width?: string;
  titleVariant?: 'inherit' | Variant;
  valVariant?: 'inherit' | Variant;
  formatter?: (value: any) => any;
  unit?: string;
  info?: string;
}

/**
 * LFCSingleValue
 */
const LFCSingleValue = (props: Props) => {
  const classes = useStyles();
  return (
    <Card
      className={classes.root}
      style={{
        width: props.width,
        margin: '2px'
      }}
    >
      <CardContent style={{padding: '5px'}}>
        {/* title */}
        <Box textAlign="center">
          <Typography variant={props.titleVariant ? props.titleVariant : 'caption'}>
            {props.title}
          </Typography>
        </Box>
        {/* body */}
        <Tooltip title={props.val != null ? String(props.val) : ''} arrow>
          <Box
            textAlign={props.align ? props.align : 'center'}
            style={{
              margin: '2px',
              whiteSpace: 'nowrap',
              overflowX: 'auto',
              overflowY: 'hidden'
            }}
          >
            <Typography
              variant={props.valVariant ? props.valVariant : 'body1'}
              style={{color: props.fontColor, fontWeight: 'bold'}}
            >
              {props.val != null
                ? props.formatter
                  ? props.formatter(props.val)
                  : String(props.val)
                : ''}
              &nbsp;
              <span style={{fontSize: '0.75rem', fontWeight: 'normal'}}>
                {props.unit ? props.unit : ''}
              </span>
            </Typography>
            {props.info !== '' ? (
              <Typography variant={'overline'} style={{color: props.fontColor}}>
                {props.info}
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        </Tooltip>
      </CardContent>
    </Card>
  );
};

export default LFCSingleValue;
