import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import LFCAlertDialog from '_components/feedback/LFCAlertDialog';
import LFCConfirmDialog from '_components/feedback/LFCConfirmDialog';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import ProgressBar from '_components/ProgressBar';
import LFCOkNgPie from '_components/surfaces/LFCOkNgPie';
import LFCSingleValuePie from '_components/surfaces/LFCSingleValuePie';
import LFCSingleValuePiePar from '_components/surfaces/LFCSingleValuePiePar';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {useMySettings} from '_contexts/MySettingsProvider';
import {useSystemSettings} from '_contexts/SystemSettingsProvider';
import {getLFCData, soracomAPIAccess} from '_logics/LFCUtil';
import useInterval from '_reacts/useInterval';
import GenericTemplate from '_templates/GenericTemplate';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Button, Divider, Grid, TextField} from '@mui/material';

/**
 * TestPage
 * @returns
 */
const TestPage = () => {
  const {t, i18n} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const mySettings = useMySettings();
  const systemSettings = useSystemSettings();
  const [startProcess, setStartProcess] = useState(false);
  const [responseData001, setData001] = useState([{total_count: '0', ng_count: '0'}]);

  const [digopenA, setDigopenA] = useState(false);
  const [digopenB, setDigopenB] = useState(false);
  const [dt, setDt] = useState('');

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const doSearch = () => {
    setStartProcess(true);
    Promise.allSettled([
      // あんどん_本日_実績数・不良数
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 125,
        parameters: {},
        ds_state: setData001,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 40001,
        parameters: {},
        cancelToken: source.token,
        t
      })
        .then(ds => {
          const tmp: {label: string}[] = ds.map((item: any) => {
            return {label: item['work']};
          });
          setWork(tmp);
        })
        .catch(e => e),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 42021,
        parameters: {},
        cancelToken: source.token,
        t
      })
        .then(ds => {
          const tmp: {label: string}[] = ds.map((item: any) => {
            return {label: item['map']};
          });
          setMap(tmp);
        })
        .catch(e => e)
      // 高負荷SQL実行
      // getLFCData({ snack: enqueueSnackbar, sql_id: 0, parameters: {}, cancelToken: source.token }).then((data) => {
      //   console.log(data);
      // }),
    ]).then(() => {
      setStartProcess(false);
    });
  };

  /**
   * PublicIpを取得します
   */
  const GetPublicIp = async () => {
    const res = await fetch('https://api.ipify.org/?format=json');
    const data = await res.json();
    return data.ip;
  };

  const doPortMappingList = async () => {
    soracomAPIAccess({
      invoke_name: 'PortMappings',
      method: 'GET',
      parameters: {
        isGlobal: true,
        destination: {imsi: '295050911885674', port: 10001},
        duration: 28800,
        tlsRequired: false
      }
    })
      .then(res => {
        console.log(res);
        alert(res);
      })
      .catch(error => {
        console.error(error.message);
        alert(`発行に失敗しました。${error.message}`);
      });
  };

  const doPortMapping = async () => {
    const gip = await GetPublicIp();

    soracomAPIAccess({
      invoke_name: 'PortMappings',
      method: 'POST',
      parameters: {
        isGlobal: true,
        destination: {imsi: '295050911885674', port: 10001},
        duration: 28800,
        source: {ipRanges: [`${gip}/30`]},
        tlsRequired: false
      }
    })
      .then(res => {
        console.log(res);
        alert(res);
      })
      .catch(error => {
        console.error(error.message);
        alert(`発行に失敗しました。${error.message}`);
      });
  };

  /**
   * 自動更新処理
   */
  useInterval(doSearch, systemSettings.RELOAD_TIME * 1000);

  useEffect(() => {
    alert(systemSettings.RELOAD_TIME);

    // マウント時処理
    doSearch();

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const changeLanguage = (lng: string) => {
    //ブラウザで設定せれている言語を確認
    const blrLang = (
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language ||
      'en'
    ).slice(0, 2);
    alert(blrLang);

    i18n.changeLanguage(lng);
  };

  const [searchValue, setSearchValue] = useState({
    work: [],
    map: []
  });
  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [map, setMap] = useState<{label: string}[]>([]);
  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  return (
    <GenericTemplate title={t(`ユーザID`)}>
      <ProgressBar startProcess={startProcess} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{m: 2}}>
            ワーク画像アップロード機能
          </Divider>
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small" // small/medium
            value={multiSelectData(work, searchValue.work)}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={false}
            selectItem={work}
          />
          <Button variant="contained">画像アップロード</Button>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <Button variant="outlined" onClick={() => changeLanguage('ja')}>
              日本語 {i18n.language}
            </Button>
            <Button variant="outlined" onClick={() => changeLanguage('en')}>
              英語 - {t(`code.judge.${2}`)}
            </Button>
            <Button variant="outlined" onClick={() => doPortMappingList()}>
              リモートList
            </Button>
            <Button variant="outlined" onClick={() => doPortMapping()}>
              リモート発行
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                window.location.reload();
              }}
            >
              reload()
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                // @ts-ignore
                window.location.reload(true);
              }}
            >
              reload(true) →うごく
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                alert(window.navigator.userAgent);
              }}
            >
              userAgent
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                window.close();
              }}
            >
              window.close()
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                navigator.serviceWorker.getRegistration().then(registration => {
                  registration?.update();
                });
              }}
            >
              即時update確認
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                window.open('https://linkwiz.co.jp/', '_blank');
              }}
            >
              外部リンク
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setDigopenA(true);
              }}
            >
              確認ダイアログ
            </Button>
            {/* <SWSuccessDialog></SWSuccessDialog> */}
            <LFCConfirmDialog
              open={digopenA}
              message="確認ダイアログ"
              onClose={isOK => {
                setDigopenA(false);
                alert(isOK);
              }}
            ></LFCConfirmDialog>
            <Button
              variant="outlined"
              onClick={() => {
                setDigopenB(true);
              }}
            >
              情報ダイアログ
            </Button>
            <LFCAlertDialog
              open={digopenB}
              message="情報ダイアログ"
              onClose={() => {
                setDigopenB(false);
              }}
            ></LFCAlertDialog>
            <LFCButton
              onClick={isOk => {
                alert(isOk);
              }}
              confirmMessage="よろしいですか？"
            >
              確認付きボタン
            </LFCButton>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <LFCDatetimePicker
            name="date01"
            label={t('日付From')}
            value={dt}
            onChange={event => {
              setDt(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="date01"
            label={t('日付From')}
            value={dt}
            type="datetime-local"
            onChange={event => {
              setDt(event.target.value);
            }}
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <LFCSingleValuePie
            title="生産数"
            source={[{val: 122}]}
            valueKey={'val'}
            targetValue={1000}
            targetComparisonSign="<="
          ></LFCSingleValuePie>
        </Grid>
        <Grid item xs={12} md={2}>
          <LFCSingleValuePie
            title="不良数"
            source={[{val: 22}]}
            valueKey={'val'}
            targetValue={100}
            targetComparisonSign=">="
          ></LFCSingleValuePie>
        </Grid>
        <Grid item xs={12} md={2}>
          <LFCSingleValuePie
            title="タクトタイム"
            source={[{val: 157.3}]}
            valueKey={'val'}
            valueUnit="sec"
            targetValue={28.0}
            targetComparisonSign=">="
          ></LFCSingleValuePie>
        </Grid>
        <Grid item xs={12} md={2}>
          <LFCSingleValuePiePar
            title="不良率"
            source={[{count: 100, total: 1000}]}
            valueKey={'count'}
            totalValueKey={'total'}
            targetPar={10}
            targetComparisonSign=">="
          ></LFCSingleValuePiePar>
        </Grid>
        <Grid item xs={12} md={12}>
          <LFCTitledCard title={`${t('本日')}`}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <LFCOkNgPie source={responseData001[0]} />
              </Grid>
              <Grid item xs={12} md={3}>
                <LFCBaseCharts
                  option={{
                    toolbox: {},
                    grid: {left: 100},
                    title: {
                      text: '不良数',
                      left: 'center'
                    },
                    tooltip: {show: true},
                    color: ['#F44336'],
                    xAxis: {},
                    yAxis: {
                      axisLabel: {width: 90, overflow: 'truncate'},
                      data: [
                        'heart',
                        'large-intestine',
                        'small-intestine',
                        'spleen',
                        'kidney',
                        'lung',
                        'liver'
                      ]
                    },
                    series: [
                      {
                        type: 'bar',
                        data: [121, 321, 141, 52, 198, 289, 139]
                      }
                    ]
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>

              <Grid item xs={12} md={2}>
                <LFCBaseCharts
                  option={{
                    toolbox: {},
                    title: {
                      text: '目標: 80以上',
                      left: 'center',
                      bottom: 0
                    },
                    // colorBy: "series",
                    select: {disabled: true},
                    series: [
                      {
                        name: '表示',
                        type: 'pie',
                        radius: ['60%', '90%'],
                        data: [
                          {value: 900, name: 'main'},
                          {value: 100, name: 'sub', label: {show: false}}
                        ],
                        itemStyle: {
                          color: (params: any) => {
                            return params['name'] === 'main' ? '#4CAF50' : 'rgba(42,42,42,0.5)';
                          }
                        },
                        label: {
                          show: true,
                          position: 'center',
                          formatter: (params: any) => {
                            const strP = {
                              title: '良品率',
                              val: params['percent'],
                              val_unit: '%',
                              val_sub: params['value'].toLocaleString()
                            };
                            return `{title|${strP.title}}\n{hr|}\n {val|${strP.val}}{val_unit|${strP.val_unit}} \n {val_sub|${strP.val_sub}} `;
                          },
                          // backgroundColor: mySettings.isDark ? "#424242" : "#FFF",
                          padding: 5,
                          rich: {
                            title: {
                              fontSize: 14,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              // align: "center",
                              // lineHeight: 25,
                              padding: [0, 0, 5, 0]
                            },
                            hr: {
                              borderColor: '#555',
                              width: '100%',
                              align: 'left',
                              borderWidth: 1,
                              height: 0
                            },
                            val: {
                              fontSize: 28,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              // align: "center",
                              fontWeight: 'bold',
                              verticalAlign: 'bottom',
                              padding: [7, 0]
                              // lineHeight: 50,
                            },
                            val_unit: {
                              fontSize: 14,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              verticalAlign: 'bottom',
                              padding: [7, 0]
                            },
                            val_sub: {
                              fontSize: 14,
                              color: '#FFF',
                              backgroundColor: '#555',
                              // align: "center",
                              borderRadius: 3,
                              padding: 5
                            }
                          }
                        }
                      }
                    ]
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <LFCBaseCharts
                  option={{
                    toolbox: {},
                    title: {
                      text: '目標: 50以上',
                      left: 'center',
                      bottom: 0
                    },
                    // colorBy: "series",
                    select: {disabled: true},
                    series: [
                      {
                        name: '表示',
                        type: 'pie',
                        radius: ['60%', '90%'],
                        data: [
                          {value: 7, name: 'main'},
                          {value: 3, name: 'sub', label: {show: false}}
                        ],
                        itemStyle: {
                          color: (params: any) => {
                            return params['name'] === 'main' ? '#4CAF50' : 'rgba(42,42,42,0.5)';
                          }
                        },
                        label: {
                          show: true,
                          position: 'center',
                          formatter: (params: any) => {
                            const strP = {
                              title: '稼働率',
                              val: params['percent'].toLocaleString(),
                              val_unit: '%',
                              val_sub: params['value'] + 'h'
                            };

                            return `{title|${strP.title}}\n{hr|}\n {val|${strP.val}}{val_unit|${strP.val_unit}} \n {val_sub|${strP.val_sub}} `;
                          },
                          // backgroundColor: mySettings.isDark ? "#424242" : "#FFF",
                          padding: 5,
                          rich: {
                            title: {
                              fontSize: 14,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              // align: "center",
                              // lineHeight: 25,
                              padding: [0, 0, 5, 0]
                            },
                            hr: {
                              borderColor: '#555',
                              width: '100%',
                              align: 'left',
                              borderWidth: 1,
                              height: 0
                            },
                            val: {
                              fontSize: 28,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              // align: "center",
                              fontWeight: 'bold',
                              verticalAlign: 'bottom',
                              padding: [7, 0]
                              // lineHeight: 50,
                            },
                            val_unit: {
                              fontSize: 14,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              verticalAlign: 'bottom',
                              padding: [7, 0]
                            },
                            val_sub: {
                              fontSize: 14,
                              color: '#FFF',
                              backgroundColor: '#555',
                              // align: "center",
                              borderRadius: 3,
                              padding: 5
                            }
                          }
                        }
                      }
                    ]
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <LFCBaseCharts
                  option={{
                    toolbox: {},
                    title: {
                      text: '目標: 50以下',
                      left: 'center',
                      bottom: 0
                    },
                    // colorBy: "series",
                    select: {disabled: true},
                    series: [
                      {
                        name: '表示',
                        type: 'pie',
                        radius: ['60%', '90%'],
                        data: [
                          {value: 35, name: 'main'},
                          {value: 15, name: 'sub', label: {show: false}}
                        ],
                        itemStyle: {
                          color: (params: any) => {
                            return params['name'] === 'main' ? '#4CAF50' : 'rgba(42,42,42,0.5)';
                          }
                        },
                        label: {
                          show: true,
                          position: 'center',
                          formatter: (params: any) => {
                            const strP = {
                              title: 'タクトタイム',
                              val: params['value'].toLocaleString(),
                              val_unit: 'sec',
                              val_sub: ''
                            };

                            return `{title|${strP.title}}\n{hr|}\n {val|${strP.val}}{val_unit|${strP.val_unit}} `;
                          },
                          // backgroundColor: mySettings.isDark ? "#424242" : "#FFF",
                          padding: 5,
                          rich: {
                            title: {
                              fontSize: 14,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              // align: "center",
                              // lineHeight: 25,
                              padding: [0, 0, 5, 0]
                            },
                            hr: {
                              borderColor: '#555',
                              width: '100%',
                              align: 'left',
                              borderWidth: 1,
                              height: 0
                            },
                            val: {
                              fontSize: 28,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              // align: "center",
                              fontWeight: 'bold',
                              verticalAlign: 'bottom',
                              padding: [7, 0]
                              // lineHeight: 50,
                            },
                            val_unit: {
                              fontSize: 14,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              verticalAlign: 'bottom',
                              padding: [7, 0]
                            },
                            val_sub: {
                              fontSize: 14,
                              color: '#FFF',
                              backgroundColor: '#555',
                              // align: "center",
                              borderRadius: 3,
                              padding: 5
                            }
                          }
                        }
                      }
                    ]
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <LFCBaseCharts
                  option={{
                    toolbox: {},
                    title: {
                      text: '目標: 80以上',
                      left: 'center',
                      bottom: 0
                    },
                    // colorBy: "series",
                    select: {disabled: true},
                    series: [
                      {
                        name: '表示',
                        type: 'pie',
                        radius: ['60%', '90%'],
                        data: [
                          {value: 400, name: 'main'},
                          {value: 600, name: 'sub', label: {show: false}}
                        ],
                        itemStyle: {
                          color: (params: any) => {
                            return params['name'] === 'main' ? '#F44336' : 'rgba(42,42,42,0.5)';
                          }
                        },
                        label: {
                          show: true,
                          position: 'center',
                          formatter: (params: any) => {
                            const strP = {
                              title: '良品率',
                              val: params['percent'],
                              val_unit: '%',
                              val_sub: params['value'].toLocaleString()
                            };
                            return `{title|${strP.title}}\n{hr|}\n {val|${strP.val}}{val_unit|${strP.val_unit}} \n {val_sub|${strP.val_sub}} `;
                          },
                          // backgroundColor: mySettings.isDark ? "#424242" : "#FFF",
                          padding: 5,
                          rich: {
                            title: {
                              fontSize: 14,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              // align: "center",
                              // lineHeight: 25,
                              padding: [0, 0, 5, 0]
                            },
                            hr: {
                              borderColor: '#555',
                              width: '100%',
                              align: 'left',
                              borderWidth: 1,
                              height: 0
                            },
                            val: {
                              fontSize: 28,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              // align: "center",
                              fontWeight: 'bold',
                              verticalAlign: 'bottom',
                              padding: [7, 0]
                              // lineHeight: 50,
                            },
                            val_unit: {
                              fontSize: 14,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              verticalAlign: 'bottom',
                              padding: [7, 0]
                            },
                            val_sub: {
                              fontSize: 14,
                              color: '#FFF',
                              textBorderWidth: 0,
                              backgroundColor: '#555',
                              // align: "center",
                              borderRadius: 3,
                              padding: 5
                            }
                          }
                        }
                      }
                    ]
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <LFCBaseCharts
                  option={{
                    toolbox: {},
                    title: {
                      text: '目標: 50以上',
                      left: 'center',
                      bottom: 0
                    },
                    // colorBy: "series",
                    select: {disabled: true},
                    series: [
                      {
                        name: '表示',
                        type: 'pie',
                        radius: ['60%', '90%'],
                        data: [
                          {value: 3, name: 'main'},
                          {value: 7, name: 'sub', label: {show: false}}
                        ],
                        itemStyle: {
                          color: (params: any) => {
                            return params['name'] === 'main' ? '#FFC107' : 'rgba(42,42,42,0.5)';
                          }
                        },
                        label: {
                          show: true,
                          position: 'center',
                          formatter: (params: any) => {
                            const strP = {
                              title: '稼働率',
                              val: params['percent'].toLocaleString(),
                              val_unit: '%',
                              val_sub: params['value'] + 'h'
                            };

                            return `{title|${strP.title}}\n{hr|}\n {val|${strP.val}}{val_unit|${strP.val_unit}} \n {val_sub|${strP.val_sub}} `;
                          },
                          // backgroundColor: mySettings.isDark ? "#424242" : "#FFF",
                          padding: 5,
                          rich: {
                            title: {
                              fontSize: 14,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              // align: "center",
                              // lineHeight: 25,
                              padding: [0, 0, 5, 0]
                            },
                            hr: {
                              borderColor: '#555',
                              width: '100%',
                              align: 'left',
                              borderWidth: 1,
                              height: 0
                            },
                            val: {
                              fontSize: 28,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              // align: "center",
                              fontWeight: 'bold',
                              verticalAlign: 'bottom',
                              padding: [7, 0]
                              // lineHeight: 50,
                            },
                            val_unit: {
                              fontSize: 14,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              verticalAlign: 'bottom',
                              padding: [7, 0]
                            },
                            val_sub: {
                              fontSize: 14,
                              color: '#FFF',
                              textBorderWidth: 0,
                              backgroundColor: '#555',
                              // align: "center",
                              borderRadius: 3,
                              padding: 5
                            }
                          }
                        }
                      }
                    ]
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <LFCBaseCharts
                  option={{
                    toolbox: {},
                    title: {
                      text: '目標: 50以下',
                      left: 'center',
                      bottom: 0
                    },
                    // colorBy: "series",
                    select: {disabled: true},
                    series: [
                      {
                        name: '表示',
                        type: 'pie',
                        radius: ['60%', '90%'],
                        data: [
                          {value: 60, name: 'main'},
                          {value: 0, name: 'sub', label: {show: false}}
                        ],
                        itemStyle: {
                          color: (params: any) => {
                            return params['name'] === 'main' ? '#F44336' : 'rgba(42,42,42,0.5)';
                          }
                        },
                        label: {
                          show: true,
                          position: 'center',
                          formatter: (params: any) => {
                            const strP = {
                              title: 'タクトタイム',
                              val: params['value'].toLocaleString(),
                              val_unit: 'sec',
                              val_sub: ''
                            };

                            return `{title|${strP.title}}\n{hr|}\n {val|${strP.val}}{val_unit|${strP.val_unit}} `;
                          },
                          // backgroundColor: mySettings.isDark ? "#424242" : "#FFF",
                          padding: 5,
                          rich: {
                            title: {
                              fontSize: 14,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              // align: "center",
                              // lineHeight: 25,
                              padding: [0, 0, 5, 0]
                            },
                            hr: {
                              borderColor: '#555',
                              width: '100%',
                              align: 'left',
                              borderWidth: 1,
                              height: 0
                            },
                            val: {
                              fontSize: 28,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              // align: "center",
                              fontWeight: 'bold',
                              verticalAlign: 'bottom',
                              padding: [7, 0]
                              // lineHeight: 50,
                            },
                            val_unit: {
                              fontSize: 14,
                              color: mySettings.isDark ? '#FFF' : '#000',
                              verticalAlign: 'bottom',
                              padding: [7, 0]
                            },
                            val_sub: {
                              fontSize: 14,
                              color: '#FFF',
                              textBorderWidth: 0,
                              backgroundColor: '#555',
                              // align: "center",
                              borderRadius: 3,
                              padding: 5
                            }
                          }
                        }
                      }
                    ]
                  }}
                />
              </Grid>
            </Grid>
          </LFCTitledCard>
        </Grid>
      </Grid>
    </GenericTemplate>
  );
};

export default TestPage;
