import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  name: string;
  data: any;
  nameKey: string;
  valueKey: string;
  valueUnit?: string;
  minPercent: number;
  onEvents?: any;
  // exportData: any;
  // exportFields: any;
  // exportFilename: string;
  height: string;
}

const LFCPieChart = (props: Props) => {
  const {t} = useTranslation();
  const [cdata, setCdata] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    if (props.data == null || props.nameKey == null || props.valueKey == null) return;

    const total = props.data.reduce((sum: number, item: any) => sum + item[props.valueKey], 0);
    const tmp = props.data
      .map((item: any) => {
        const pct = (item[props.valueKey] / total) * 100;

        return {
          name: item[props.nameKey],
          value: item[props.valueKey],
          label: {show: props.minPercent <= pct},
          labelLine: {show: props.minPercent <= pct}
        };
      })
      // valueに基づいて降順ソート
      .sort((a: any, b: any) => b.value - a.value);

    setCdata(tmp);
  }, [props.data, props.nameKey, props.valueKey]);

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.name
        },
        tooltip: {
          trigger: 'item',
          formatter: (prm: any) => {
            const {name, value, percent} = prm;
            const percentStr = percent !== undefined ? `${percent.toFixed(1)} %` : '';
            return `${name}<br>${value} ${t(props.valueUnit || '')}<br>${percentStr}`;
          }
          // position: 'inside'
        },
        legend: {
          show: false
        },
        series: [
          {
            name: 'sim',
            type: 'pie',
            data: cdata
          }
        ]
      }}
      onEvents={props.onEvents}
      // exportData={props.exportData}
      // exportFields={props.exportFields}
      // exportFilename={props.exportFilename}
      height={props.height}
    />
  );
};

export default LFCPieChart;
